<template>
    <div class="consignment-notes-registries">

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Перечень реестров товарных накладных</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="outline-primary"
                    v-b-modal.import>Импортировать
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    to="/create-consignment-notes-registry"
                >Создать
                </BButton>
            </div>

        </div>

        <TableFilter
            :columns="consignmentNotesRegistriesFields"
            @update-selected-columns="columns = $event"
            @search="filter = $event"
            @clear="clearFilterData"
            @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
        />

        <DefaultTable
            :data="consignmentNotesRegistries"
            :fields="consignmentNotesRegistriesFieldsFiltered"
            :filter="filter"
            :loading="loading"
            rowLink="/consignment-notes-registries"
            emptyText="Нет реестров платежей"
            downloadFor="consignment-registers"
            :noLocalSorting="true"
            @sort-change="sorting"
            @pagination-change="paginationChange"
            @per-page-change="perPageChange"
        />

        <FilterSidebar
            id="filter"
            v-model="filterData"
            @apply="updateDataWithFilters"
        />

        <b-modal
            id="import"
            centered title="Импорт реестров товарных накладных">

            <div class="p-2 bg-light">

                <div class="font-weight-bold text-muted">Файл для импорта <span class="text-danger">*</span></div>

                <b-form-file
                    v-model="importFile"
                    class="mt-1"
                    plain />

            </div>

            <template #modal-footer="{ hide }">
                <b-button variant="outline-primary" @click="hide()">
                    Отмена
                </b-button>
                <b-button
                    variant="primary"
                    @click="sendImport">
                    Импортировать
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="import-success"
            centered title="Импорт завершён">

            <div class="p-2 bg-light">

                <p>Импортирование файла «{{ importFile ? importFile.name : '' }}» завершено</p>

            </div>

            <template #modal-footer="{ hide }">
                <b-button
                    variant="primary"
                    @click="hide()">
                    Хорошо
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
import TableFilter from "@/components/TableFilter"
import DefaultTable from "@/components/Tables/Default"
import FilterSidebar from "@/components/FilterSidebar"
import { mapState } from 'vuex'

function initialFilterData() {
    return {
        number: '',
        provider_status: '',
        date: ['', ''],
        provider_id: '',
        provider_contract_id: '',
        provider_contract_date: ''
    }
}

export default {
    name: "ConsignmentNotesRegistries",
    components: {
        TableFilter,
        DefaultTable,
        FilterSidebar
    },
    computed: {
        ...mapState({
            consignmentNotesRegistries: state => state.consignmentNotesRegistries.consignmentNotesRegistries
        }),
        consignmentNotesRegistriesFieldsFiltered () {
            let res = []
            this.consignmentNotesRegistriesFields.forEach(item => {
                if (this.columns.filter(col => col === item.label).length) {
                    res.push(item)
                }
            })
            return res.length > 0 ? res : this.consignmentNotesRegistriesFields
        }
    },
    data: () => ({
        consignmentNotesRegistriesFields: [
            {
                key: 'consignment_number',
                nesting: 'consignment_number',
                sortable: false,
                label: 'Номер РТН',
                full: 'Номер РТН',
                openDefault: true
            },
            {
                key: 'date',
                sortable: false,
                label: 'Дата',
                full: 'Дата',
                openDefault: true
            },
            {
                key: 'customer_status',
                sortable: true,
                label: 'Статус согласования с заказчиком',
                full: 'Статус согласования с заказчиком',
                openDefault: true
            },
            {
                key: 'contr_agent_status',
                sortable: true,
                label: 'Статус согласования с контрагентом',
                full: 'Статус согласования с контрагентом',
                openDefault: true
            },
            {
                key: 'organization',
                nesting: 'organization_id',
                sortable: true,
                label: 'Филиал',
                full: 'Филиал заказчик',
                openDefault: true
            },
            {
                key: 'contractor',
                nesting: 'contractor_contr_agent_id',
                sortable: true,
                label: 'Подрядчик',
                full: 'Подрядчик',
                openDefault: true
            },
            {
                key: 'provider',
                nesting: 'provider_contr_agent_id',
                sortable: true,
                label: 'Поставщик',
                full: 'Поставщик',
                openDefault: true
            },
            {
                key: 'object',
                nesting: 'object_id',
                sortable: true,
                label: 'Объект',
                full: 'Объект',
                openDefault: true
            },
            {
                key: 'sub_object',
                nesting: 'sub_object_id',
                sortable: true,
                label: 'Подобъект',
                full: 'Подобъект',
                openDefault: true
            },
            {
                key: 'responsible_full_name',
                sortable: true,
                label: 'Ответственный исполнитель',
                full: 'Ответственный исполнитель',
                openDefault: true
            },
            {
                key: 'responsible_phone',
                sortable: true,
                label: 'Телефон',
                full: 'Телефон',
                openDefault: true
            },
            {
                key: 'comment',
                sortable: true,
                label: 'Комм.',
                full: 'Текст комментария',
                openDefault: true
            },
            {
                key: 'file_url',
                sortable: false,
                label: 'Приложение',
                full: 'Приложение',
                openDefault: true
            },
        ],
        columns: [],
        filter: '',
        filterData: initialFilterData(),
        importFile: '',
        loading: false,
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Перечень реестров товарных накладных' }
        ],
        sortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        }
    }),
    methods: {
        async getData() {
            this.loading = true
            await this.$store.dispatch('getConsignmentNotesRegistries')
            this.loading = false
        },
        clearFilterData () {
            this.filterData = initialFilterData()
            this.getData()
        },
        sendImport () {
            if (this.importFile !== null) {
                this.$bvModal.show('import-success')
                this.$bvModal.hide('import')
            } else {
                this.$bvToast.toast('Вам необходимо загрузить файл формата XML', {
                    title: 'Вы не прикрепили файл для импорта',
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
            }
        },
        async updateDataWithFilters (clearSort) {
            this.loading = true
            if (clearSort) {
                this.sortingData.sort_field = ''
                this.sortingData.sort_order = ''
                this.sortingData.page = ''
            }
            await this.$store.dispatch('getConsignmentNotesRegistries', {
                ...this.filterData,
                ...this.sortingData
            })
            this.loading = false
        },
        sorting (event) {
            this.sortingData.sort_field = event.sortBy
            this.sortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateDataWithFilters()
        },
        paginationChange (page) {
            this.sortingData.page = page
            this.updateDataWithFilters()
        },
        perPageChange (perPage) {
            this.sortingData.page = ''
            this.sortingData.per_page = perPage
            this.updateDataWithFilters()
        }
    },
    mounted () {
        this.getData()
        this.consignmentNotesRegistriesFields.forEach(item => {
            if (item.openDefault) {
                this.columns.push(item.label)
            }
        })
    }
}
</script>

<style scoped>

</style>
